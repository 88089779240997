
















import { Component, Vue } from 'vue-property-decorator';
import UserAvatar from "@/components/user/UserAvatar.vue";
import UserProfile from "@/components/user/profile/UserProfile.vue";

@Component({
  components: {UserProfile, UserAvatar},
})
export default class UserSidebar extends Vue {
  get me() {
    return this.$store.state.me;
  }

  get hasStatus() {
    if(this.me.status) {
      return this.me.status.emoji;
    } else {
      return null;
    }
  }

  get hasUnreadNotification() {
    let unReadNotification = this.$store.state.me.notifications.data.filter((noti: any) => {
      return noti.read_at == null;
    });

    if(unReadNotification.length > 0){
      return true;
    }
    return false;
  }
}
